<script setup>
import { ref, onMounted } from 'vue';

const appInstalled = ref('');
const deferredPrompt = ref(null);
const showInstallPrompt = ref(false);

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  let appType = '';
  if (document.referrer.startsWith('android-app://')) {
    appType = 'twa';
  } else if (navigator.standalone || isStandalone) {
    appType = 'standalone';
  }
  appType = 'browser';
  return appType;
}

// Detects if device is on iOS
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

onMounted(() => {
  // Initialize deferredPrompt for use later to show browser install prompt.
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    // e.preventDefault();
    console.log(e);
    // Stash the event so it can be triggered later.
    deferredPrompt.value = e;
    // Update UI notify the user they can install the PWA
    // showInstallPromotion();
    showInstallPrompt.value = true;
    // Optionally, send analytics event that PWA install promo was shown.
    console.log('beforeinstallprompt event was fired.');
  });

  window.addEventListener('appinstalled', () => {
    // Hide the app-provided install promotion
    // hideInstallPromotion();
    showInstallPrompt.value = false;
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt.value = null;
    // Optionally, send analytics event to indicate successful install
    console.log('PWA was installed');
  });

  appInstalled.value = getPWADisplayMode();
  console.log('appInstalled:');
  console.log(appInstalled.value);
  console.log(window.navigator.userAgent);

  // Checks if should display install popup notification:
  if (isIos() && !isInStandaloneMode()) {
    console.log('Kann installiert werden!');
    // showInstallPrompt.value = true;
    // this.setState({ showInstallMessage: true });
  }
});

function installApp(e) {
  // Show the prompt
  console.log('deferredPrompt:');
  console.log(deferredPrompt.value);
  deferredPrompt.value.prompt();
  // Wait for the user to respond to the prompt
  deferredPrompt.value.userChoice
    .then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt.value = null;
    });
  /*
  const { outcome } = await deferredPrompt.value.userChoice;
  console.log(`User response to the isntall propt: ${outcome}`);
  deferredPrompt.value = null;
  */
}

</script>

<template>
  <div
    v-if="showInstallPrompt"
    class="bg-gray-900 rounded-xl"
    @click="installApp()"
    @keydown="1"
    >
    <div class="p-2 flex flex-col items-center">
      <img
        alt="Icon1"
        src="../assets/icon_arrow_88.png"
        class="w-16 h-16 p-2"
      />
      <p class="text-sm text-white">Bürgerapp<br />installieren</p>
    </div>
  </div>
</template>
